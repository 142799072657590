<template>
  <div id="splash-img" :class="{ 'sm': small, 'tiled': tiled }" class="splash-img" :style="{ backgroundImage: 'url(' + splashImageUrl + ')' }">

    <div class="splash-img-overlay">

    </div>
    <div class="splash-text-container d-flex flex-column align-items-center">
  <h2 v-html="title"></h2>
  <h1 style="line-height:80px;" v-html="subtitle"></h1>
  <slot></slot>
</div>

    <img alt="scroll indicator" class="scroll-indicator" src="../assets/img/mouse-scroll.webp">

  </div>
</template>

<script>
export default {
  name: 'SplashImage',
  props: {
    title: {
      type: String,
      default: null
    },
    subtitle: {
      type: String,
      default: null
    },
    splashImageUrl: {
      type: String,
      default: null
    },
    small: {
      type: Boolean,
      default: false
    },
    tiled: {
      type: Boolean,
      default: false
    },
  },
  mounted() {

    var deviceHeight = window.innerHeight;

    var root = document.documentElement;

// Set the value of a CSS variable
root.style.setProperty('--screen-height', `${deviceHeight}px`);
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.splash-img {
  position: relative;
  background-attachment: fixed;
  background-attachment:scroll;
  background-position: center center;
  height: var(--app-height);
  display: flex;
  color: white;
  background-repeat: no-repeat;
  background-size: cover;
}

.tiled {
  background-repeat: repeat;
  background-size: 600px 700px;
}


.splash-img-overlay {
  height: var(--app-height);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.8), transparent);

  background-color: rgba(0, 0, 0, 0.5);
}

.splash-text-container {
  position: absolute;
  top: 50%;
  left: 50%;
  text-align: center;
  transform: translate(-50%, -50%);
  width:100%;
}

.splash-text-container > h1 {
  font-size: 45px !important;
  font-family: 'Pacifico', cursive !important;
  font-weight: 1;
  margin-top: 10px;
  /* font-family: 'Oswald', sans-serif; */
  letter-spacing: 0.1em; /* Adjust the spacing as needed */


}

.scroll-indicator {
  position: absolute;
  bottom: 10px;
  z-index: 99;
  left: 50%;
  height: 50px;
  transform: translateX(-50%);
}

.splash-text-container h2 {
  font-family: 'Lobster', 'Comic Sans MS', cursive;

}


@media only screen and (min-height: 1200px) {

  .splash-img.sm,
  .splash-img.sm .splash-img-overlay {
    height: var(--app-height);
  }
}

@media screen and (min-height: 900px) and (max-height:1199px) {

  .splash-img.sm,
  .splash-img.sm .splash-img-overlay {
    height: calc(var(--app-height) / 1.15);
  }



}

@media screen and (min-height: 600px) and (max-height:899px) {

  .splash-img.sm,
  .splash-img.sm .splash-img-overlay {
    height: calc(var(--app-height) / 1.25);
  }
}


@media (max-width:768px) {


  .splash-text-container h1 {
 font-size:34px;
 line-height: 50px !important;
}

.splash-text-container h2 {
 font-size:20px;
 line-height: 30px !important;
} 

}

@media (max-width:568px) {


.splash-text-container h1 {
font-size:30px;
line-height: 45px !important;
}


}
</style>
